import {Component, OnInit, ViewChild} from '@angular/core';
import {NgForm} from "@angular/forms";
import {IReport} from "../../Models/ireport.model";
import {ReportService} from "../../Services/report.service";

@Component({
  selector: 'app-report-follow',
  templateUrl: './report-follow.component.html',
  styleUrls: ['./report-follow.component.css']
})
export class ReportFollowComponent implements OnInit {
  reports: IReport[] = [];
  //The inserted code to follow the report
  follow_code: string = "";
  downloadUrl: string = "https://zerotolerance-be.ciimar.up.pt/api/report/download/";
  evidenceFilename: string | undefined = "";
  @ViewChild('followForm') form: NgForm | undefined;

  constructor(private reportService: ReportService) {
  }

  ngOnInit(): void {
    this.reportService.getAllReports().subscribe((data: any) => {
      this.reports = data;
    })
  }

  followReport(form: NgForm) {
    //console.log("INSERTED CODE: " + this.follow_code.toString());
  }

  onFollowClicked(code: string) {
    //Get the report Code
    let actual_code = this.reports?.find((report) => {
      return report.code === code;
    })

    // @ts-ignore
    if (actual_code.email === "" || actual_code.email === undefined || actual_code.email === null) {
      // @ts-ignore
      actual_code.email = "Anonymous";
    }
    // @ts-ignore
    if (actual_code.witnesses === "" || actual_code.witnesses === undefined || actual_code.witnesses === null) {
      // @ts-ignore
      actual_code.witnesses = "No witnesses were identified";
    }

    this.evidenceFilename = actual_code?.evidences.toString();

    //populate the form with the report details
    this.form?.setValue({
      // @ts-ignore
      email: actual_code.email,
      // @ts-ignore
      witnesses: actual_code.witnesses,
      // @ts-ignore
      type: actual_code.type,
      // @ts-ignore
      occurrenceDate: actual_code.occurrenceDate,
      // @ts-ignore
      reportDate: actual_code.reportDate,
      // @ts-ignore
      description: actual_code.description,
      // @ts-ignore
      status: actual_code.status,
      // @ts-ignore
      decision: actual_code.decision,
      // @ts-ignore
      extraInfo: actual_code.extraInfo,
    });
  }

}
