import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IUser} from "../Models/iuser.model";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userURL = 'https://zerotolerance-be.ciimar.up.pt/api/auth/users/';

  constructor(private http: HttpClient) { }

  getUserByEmail(email: string) {
    return this.http.get<IUser>(this.userURL + email);
  }
}
