import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {HomeComponent} from "./Components/home/home.component";
import {ReportCreateComponent} from "./Components/report-create/report-create.component";
import {ReportFollowComponent} from "./Components/report-follow/report-follow.component";
import {ReportManageComponent} from "./Components/report-manage/report-manage.component";
import {SigninComponent} from "./Components/signin/signin.component";
import {RegisterComponent} from "./Components/register/register.component";

const routes: Routes = [
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'report/create', component: ReportCreateComponent},
  {path: 'report/follow', component: ReportFollowComponent},
  {path: 'report/manage', component: ReportManageComponent},
  {path: 'signin', component: SigninComponent},
  {path: 'register', component: RegisterComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
