import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IReport} from "../Models/ireport.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  reportURL = 'https://zerotolerance-be.ciimar.up.pt/api/report/';
  reportUploadURL = 'https://zerotolerance-be.ciimar.up.pt/api/report/upload/';

  constructor(private http: HttpClient) { }

  addReport(report: IReport): Observable<IReport> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<IReport>(this.reportURL, report, {headers});
  }

  getAllReports() {
    return this.http.get<IReport[]>(this.reportURL);
  }

  getReportById(reportId: string) {
    return this.http.get<IReport>(this.reportURL + reportId);
  }

  getReportByCode(code: string) {
    return this.http.get<IReport>(this.reportURL + code);
  }

  editReportById(report: IReport) {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.put<IReport>(this.reportURL + report.reportId, report, {headers});
  }

  uploadImage(form: FormData) {
    return this.http.post(this.reportUploadURL, form,)
  }
}
