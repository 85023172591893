import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IRole} from "../Models/irole.model";

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  roleURL = "https://zerotolerance-be.ciimar.up.pt/api/roles/";
  constructor(private http: HttpClient) { }

  getAllRoles() {
    return this.http.get<IRole[]>(this.roleURL);
  }
}
