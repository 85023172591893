import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IRegister} from "../Models/iregister.model";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RegisterService {
  signupURL = 'http://192.168.11.206/api/auth/signup';
  constructor(private http: HttpClient) { }

  signUp(signup: IRegister): Observable<IRegister> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<IRegister>(this.signupURL, signup, {headers});
  }
}
