import {Component, OnInit} from '@angular/core';
import {ReportService} from "../../Services/report.service";
import {NgForm} from "@angular/forms";
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";


@Component({
  selector: 'app-report-create',
  templateUrl: './report-create.component.html',
  styleUrls: ['./report-create.component.css'],
})
export class ReportCreateComponent implements OnInit {
  public successMessage = 'Report created successfully!';
  public errorMessage = 'Error while creating Report';
  public allReports: any;
  public lenReports: any;
  //Para as notificações
  public reportCreated: boolean | undefined;
  //anon vai ficar com o valor 'yes' ou 'no' do radio button sobre se é anonymous
  anon: string;
  //o email no caso de não ser anonymous
  email_anon: string;
  //witness vai ficar com o valor 'yes' ou 'no' do radio button sobre se existem testemunhas
  witness: string;
  //o nome no caso de haver testemunhas
  who: string;
  //categorias que aparecem no front-end
  categoriesList = ["Fraud", "Corruption", "Discrimination", "Harassment", "Ethical violations", "Safety violations", "Intellectual property violations", "Interest conflicts", "Other"];
  // @ts-ignore
  occurrenceDate_p: NgbDateStruct;
  //Para guardar o type do report
  type_category: string = "";
  //Para guardar o código para mostrar no html
  aux_code = this.generateRandomCode().toString();
  //Para dar disable ao button enquanto vazio
  description_d: string = "";
  category_d: string = "";
  //Evidences/Provas
  evidences_d: string = "";
  //É membro do CIIMAR?
  member: string;
  //Suspect
  suspect_anon: string = "";
  //Terms
  blockCheck: boolean = false;

  constructor(private reportService: ReportService) {
    this.anon = '';
    this.email_anon = '';
    this.witness = '';
    this.who = '';
    this.member = '';
  }

  ngOnInit(): void {
  }

  addReport(form: NgForm) {
    //Para adicionar o ID automaticamente
    let finalId: string | number;
    let aux = 0;
    let aux_date: string = "";
    let aux_currentDate: string = "";
    this.allReports = this.reportService.getAllReports().subscribe((data: any) => {
      this.allReports = data;

      if (this.allReports.length === 0) {
        finalId = 1;
      } else {
        for (let i=0; i < this.allReports.length; i++) {
          if (parseInt(this.allReports.at(i).reportId) > aux) {
            aux = parseInt(this.allReports.at(i).reportId);
          }
        }
        finalId = aux;
        // @ts-ignore
        finalId = (parseInt(finalId) + 1).toString();
      }

      aux_date = this.occurrenceDate_p.year + "/" + this.occurrenceDate_p.month + "/" + this.occurrenceDate_p.day;
      const currentDate: Date = new Date();
      aux_currentDate = currentDate.getFullYear().toString() + "/" + (currentDate.getMonth()+1).toString() + "/" + currentDate.getDate().toString();

      let testData:FormData = new FormData();

      let file: any;
      let parts: any;
      let finalName: any;
      // @ts-ignore
      if (document.getElementById("evidence_d").files[0] === null || document.getElementById("evidence_d").files[0] === undefined) {
        file = "";
        finalName = "";
      } else {
        // @ts-ignore
        file = document.getElementById("evidence_d").files[0] as File;
        parts = file.name.split('.') as string[];
        finalName = `${parts[0]}_${moment().format('YYYY_MM_DD_HH_mm_ss')}.${parts[1]}`
        testData.append('file_upload', file, finalName);
      }
      console.log("Testar o IF do File: " + file.toString());
      //const parts = file.name.split('.') as string[];
      //const formatter = new DateFormatter()
      //const finalName = `${parts[0]}_${moment().format('YYYY_MM_DD_HH_mm_ss')}.${parts[1]}`

      //testData.append('file_upload', file, finalName);
      console.log("testData: " + testData.get("file_upload"));

      this.reportService.uploadImage(testData).subscribe((data: any) => {
        this.reportService.addReport({
          reportId: finalId.toString(),
          description: form.value.description,
          email: form.value.email,
          witnesses: form.value.witnesses,
          status: "Submitted",
          code: this.aux_code,
          type: this.type_category,
          anonymous: form.value.anonymous,
          occurrenceDate: aux_date,
          reportDate: aux_currentDate,
          decision: "Pending",
          extraInfo: "",
          evidences: finalName,
          belongsTo: this.member,
          suspect: form.value.suspect,
          evidencesManager: ""
        }).subscribe((data: any) => {
          this.reportCreated = true;
          this.scrollToTop();
          this.successMessage;
        }, (error: { message: string; }) => {
          this.reportCreated = false;
          this.errorMessage;
        })
      });
    })
  }

  generateRandomCode(): string {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  }

  chooseCategory(e: any) {
    this.type_category = e.target.value;
  }

  uploadFile(e: any): void {
    this.evidences_d = e.target.files;
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  waitThreeSeconds(): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }
}
