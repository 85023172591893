<header id="header" class="d-flex align-items-center">
  <div class="container d-flex align-items-center justify-content-between">
    <h1 class="logo"><a href="index.html"><img src="assets/img/ciimar-logo.png" class="img-fluid" alt=""></a></h1>
    <nav id="navbar" class="navbar">
      <ul>
        <li><a class="nav-link scrollto" href="#hero">Home</a></li>
        <li><a class="nav-link scrollto" href="/home#about">About</a></li>
        <li class="dropdown"><a class="dropdown-active active"><span>Report</span> <i class="bi bi-chevron-down"></i></a>
          <ul>
            <li><a [routerLink]="['/report/create']">Create a new Report</a></li>
            <li><a [routerLink]="['/report/follow']">Follow the Report</a></li>
            <!--<li><a [routerLink]="['/report/manage']">Manage the Report</a></li>-->
          </ul>
        </li>
        <li><a class="nav-link scrollto" href="/home#footer">Contact</a></li>
        <li></li>
        <li></li>
        <li></li>
        <li><a class="nav-link scrollto" href="/register" id="registerLogin">Register User</a></li>
        <li><a class="nav-link scrollto" href="/" (click)="dataRemove()">LogOut</a></li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>
  </div>
</header>

<!-- Report Manage Section -->
<section id="report" class="route section-bg">
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>Report</h2>
      <h3>Here you can manage the existing <span>Reports</span>.</h3>
      <p><i>“Promoting Transparency, Amplifying Voices with CIIMAR Whistleblower”</i></p>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div class="row">
          <div *ngIf="reportEdited" class="alert alert-success d-flex align-items-center" role="alert">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {{this.successMessage}}
          </div>
          <div *ngIf="!reportEdited && reportEdited != null" class="alert alert-warning d-flex align-items-center">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            {{this.errorMessage}}
          </div>
        </div>
      </div>
    </div>
    <!-- search -->
    <nav class="navbar">
      <input class="form-control" type="search" autocomplete="off" name="warehouseId"
             placeholder="Enter report description to search" [(ngModel)]="search_aux" (ngModelChange)="search()" id="searchfield">
    </nav>
    <!-- end search -->
    <div class="row mt-4">
      <!-- TABELA COM OS REPORTS EXISTENTES -->
      <div class="row mt-4">
        <div class="col">
          <div class="row">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Email</th>
                  <th scope="col">Type</th>
                  <th scope="col">Occurrence Date</th>
                  <th scope="col" (click)="sort('reportDate')">Report Date <i *ngIf="reverse===false" class="bi bi-sort-up-alt"></i>
                    <i *ngIf="reverse===true" class="bi bi-sort-down-alt"></i></th>
                  <th scope="col">Description</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let report of reports | orderBy: key: reverse | paginate: {itemsPerPage: reportsPerPage, currentPage: pag};"
                    [ngClass]="{
                      'yellow-background': report.status === 'Submitted',
                      'green-background': report.status === 'Resolved' || report.status === 'Closed'
                    }">
                  <td>{{ report.email }}</td>
                  <td>{{ report.type }}</td>
                  <td>{{ report.occurrenceDate }}</td>
                  <td>{{ report.reportDate }}</td>
                  <td>{{ report.description }}</td>
                  <td>
                    <div class="submit-button">
                      <button type="submit" id="getReport" class="btn btn-primary" (click)="onEditClicked(report.reportId)">Get the report!</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <br>
          <pagination-controls (pageChange)="pag = $event"></pagination-controls>
          <label>Reports per page:
            <select [(ngModel)]="reportsPerPage" id="itemsoptions">
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
          </label>
        </div>
      </div>
      <!-- EDITAR REPORTS -->
      <form #manageForm="ngForm" (ngSubmit)="manageReport(manageForm)">
        <div class="container" data-aos="fade-up">
          <div class="section-title">
            <h3>Check the <span>Report</span>.</h3>
          </div>
        </div>
        <!--Fill fields-->
        <div class="form-group">
          <label>Anonymous</label><br>
          <input class="form-control" type="text" name="anonymous" id="anonymous" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>Email</label><br>
          <input class="form-control" type="text" name="email" id="email" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>CIIMAR Member</label><br>
          <input class="form-control" type="text" name="belongsTo" id="belongsTo" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>Suspect(s)</label><br>
          <input class="form-control" type="text" name="suspect" id="suspect" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>Witnesses</label><br>
          <input class="form-control" type="text" name="witnesses" id="witnesses" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>Type</label><br>
          <input class="form-control" type="text" name="type" id="type" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>Code</label><br>
          <input class="form-control" type="text" name="code" id="code" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>Occurrence Date</label><br>
          <input class="form-control" type="text" name="occurrenceDate" id="occurrenceDate" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>Report Date</label><br>
          <input class="form-control" type="text" name="reportDate" id="reportDate" ngModel readonly style="background-color: lightgrey;">
        </div>
        <br>
        <div class="form-group">
          <label>Description</label><br>
          <textarea class="form-control" type="text" name="description" id="description" ngModel readonly style="background-color: lightgrey;"></textarea>
        </div>
        <br>
        <div class="form-group">
          <label for="status">Status</label>
          <select name="status" id="status" class="form-control" [(ngModel)]="status_r" (change)="chooseStatus($event)">
            <option>Select Status</option>
            <option *ngFor="let stat of status_list" [value]="stat.toString()">
              {{stat}}
            </option>
          </select>
        </div>
        <br>
        <div class="form-group">
          <label for="decision">Decision</label>
          <select name="decision" id="decision" class="form-control" [(ngModel)]="decision_r" (change)="chooseDecision($event)">
            <option>Select Decision</option>
            <option *ngFor="let dec of decision_list" [value]="dec.toString()">
              {{dec}}
            </option>
          </select>
        </div>
        <br>
        <div class="form-group">
          <label>Extra Info</label><br>
          <textarea class="form-control" type="text" name="extraInfo" ngModel></textarea>
        </div>
        <br>
        <div>
          <label for="evidence_manager">Upload of Evidence Supporting the Investigation. (if you want to upload more than one piece of evidence, please zip a folder)</label>
          <input mdbInput class="form-control" id="evidence_manager" name="evidence_manager" type="file" [(ngModel)]="evidences_manager">
        </div>
        <div class="submit-button">
          <br>
          <input type="submit" [disabled]="!editMode" value="{{editMode ? 'Update report' : 'Click on \'Get the report!\' to edit'}}" class="btn btn-primary">
        </div>
      </form>
      <!-- Pôr um if e se variável do .ts com evidences != "" então aparece o campo -->
      <br>
      <div class="row">
        <div class="col-sm-5 col-md-6 submit-button" *ngIf="evidenceFilename!=''">
          <h5>Evidences submitted by the Whistleblower</h5>
          <br>
          <a href="{{downloadUrl}}{{evidenceFilename}}">
            <button [disabled]="!editMode" class="btn btn-primary">Download Files</button>
          </a>
        </div>
        <div class="col-sm-5 offset-sm-2 col-md-6 offset-md-0 submit-button" *ngIf="evidenceFilenameManager!=''">
          <h5>Evidences Supporting the Investigation</h5>
          <br>
          <a href="{{downloadUrl}}{{evidenceFilenameManager}}">
            <button [disabled]="!editMode" class="btn btn-primary">Download Files</button>
          </a>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- End Report Create Section -->

<app-footer></app-footer>
