import { Component } from '@angular/core';

@Component({
  selector: 'app-error403',
  templateUrl: './error403.component.html',
  styleUrls: ['./error403.component.css']
})
export class Error403Component {
  constructor() {
  }
}
