<header id="header" class="d-flex align-items-center" xmlns="http://www.w3.org/1999/html">
  <div class="container d-flex align-items-center justify-content-between">
    <h1 class="logo"><a href="index.html"><img src="assets/img/ciimar-logo.png" class="img-fluid" alt=""></a></h1>
    <nav id="navbar" class="navbar">
      <ul>
        <li><a class="nav-link scrollto" href="#hero">Home</a></li>
        <li><a class="nav-link scrollto" href="/home#about">About</a></li>
        <li class="dropdown"><a class="dropdown-active active"><span>Report</span> <i
          class="bi bi-chevron-down"></i></a>
          <ul>
            <li><a [routerLink]="['/report/create']">Create a new Report</a></li>
            <li><a [routerLink]="['/report/follow']">Follow the Report</a></li>
            <!--<li><a [routerLink]="['/report/manage']">Manage the Report</a></li>-->
          </ul>
        </li>
        <li><a class="nav-link scrollto" href="/home#footer">Contact</a></li>
        <li></li>
        <li></li>
        <li></li>
        <li><a class="nav-link scrollto" href="/signin">Admin</a></li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>
  </div>
</header>

<!-- Report Create Section -->
<section id="report" class="route section-bg">
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>Report</h2>
      <h3>Here you can create a new <span>Report</span>.</h3>
      <p><i>“Be Heard, Be Protected with CIIMAR Whistleblower”</i></p>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div class="row">
          <div *ngIf="reportCreated" class="alert alert-success d-flex align-items-center" role="alert">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
            {{this.successMessage}}
          </div>
          <div *ngIf="reportCreated" class="alert alert-info d-flex align-items-center" role="alert">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Info:">
              <path
                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
            </svg>
            <b>{{"Save this code to be able to follow the parsing process: " + this.aux_code}}</b>
          </div>
          <div *ngIf="!reportCreated && reportCreated != null" class="alert alert-warning d-flex align-items-center">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
              <path
                d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
            </svg>
            {{this.errorMessage}}
          </div>
        </div>
      </div>
    </div>
    <form #reportForm="ngForm" (ngSubmit)="addReport(reportForm)" enctype="multipart/form-data">
      <br>
      <div class="form-group">
        <label for="anonymous" id="anonymous">Anonymous</label><br>
        <input mdbRadio class="form-check-input" type="radio" id="anonymous_y" [(ngModel)]="anon" name="anonymous"
               value="yes">
        <label for="anonymous_y">Yes</label><br>
        <input mdbRadio class="form-check-input" type="radio" id="anonymous_n" [(ngModel)]="anon" name="anonymous"
               value="no">
        <label for="anonymous_n">No</label><br>
      </div>
      <div class="form-group" *ngIf="anon === 'no'">
        <label mdbLabel class="form-label" for="email">Email</label>
        <input mdbInput class="form-control" type="email" id="email" name="email" [(ngModel)]="email_anon"
               placeholder="Insert email">
      </div>
      <br>
      <div class="form-group">
        <label for="belongsTo_yn" id="belongsTo_yn">Are you a CIIMAR member?</label><br>
        <input mdbRadio class="form-check-input" type="radio" id="belongsTo_y" [(ngModel)]="member" name="belongsTo_y" value="yes">
        <label for="belongsTo_y">Yes</label><br>
        <input mdbRadio class="form-check-input" type="radio" id="belongsTo_n" [(ngModel)]="member" name="belongsTo_n" value="no">
        <label for="belongsTo_n">No</label><br>
      </div>
      <br>
      <div class="form-group">
        <label mdbLabel class="form-label" for="suspect">Identification of the suspect(s) - natural persons or entities</label>
        <input mdbInput class="form-control" type="text" id="suspect" name="suspect" [(ngModel)]="suspect_anon"
               placeholder="Insert the suspect(s) name">
      </div>
      <br>
      <div class="form-group">
        <label for="witnesses_yn" id="witnesses_yn">Witnesses</label><br>
        <input mdbRadio class="form-check-input" type="radio" id="witnesses_y" [(ngModel)]="witness" name="witnesses_y"
               value="yes">
        <label for="witnesses_y">Yes</label><br>
        <input mdbRadio class="form-check-input" type="radio" id="witnesses_n" [(ngModel)]="witness" name="witnesses_n"
               value="no">
        <label for="witnesses_n">No</label><br>
      </div>
      <div class="form-group" *ngIf="witness === 'yes'">
        <label mdbLabel class="form-label" for="witnesses">Who?</label>
        <input mdbInput class="form-control" type="text" id="witnesses" name="witnesses" [(ngModel)]="who"
               placeholder="Insert the witness's name">
      </div>
      <br>
      <div class="form-group">
        <label for="type">Category</label>
        <select name="type" id="type" class="form-control" [(ngModel)]="category_d" (change)="chooseCategory($event)">
          <option>Select category</option>
          <option *ngFor="let category of categoriesList" [value]="category.toString()">
            {{category}}
          </option>
        </select>
      </div>
      <br>
      <form class="row row-cols-sm-auto">
        <div class="col-12">
          <label>Occurrence Date</label>
          <div class="input-group">
            <input
              class="form-control"
              placeholder="yyyy-mm-dd"
              name="dp"
              [(ngModel)]="occurrenceDate_p"
              ngbDatepicker
              #d="ngbDatepicker"
            />
            <button class="btn btn-outline-secondary bi bi-calendar3" (click)="d.toggle()" type="button"></button>
          </div>
        </div>
      </form>
      <br>
      <div class="form-group">
        <label for="description">Description</label>
        <textarea class="form-control" [(ngModel)]="description_d" name="description" id="description"
                  class="form-control" placeholder="Insert Description" rows="4"></textarea>
      </div>
      <br>
      <div>
        <label for="evidence_d">Upload Evidences (if you want to upload more than one piece of evidence, please zip a folder)</label>
        <input mdbInput class="form-control" id="evidence_d" name="evidence_d" type="file" [(ngModel)]="evidences_d">
      </div>
      <br>
      <div class="mb-md-5">
        <div class="form-check">
          <input class="form-check-input" required type="checkbox" value="blockCheck" id="blockCheck" [(ngModel)]="blockCheck" id="iblockCheck" name="blockCheck">
          <label class="form-check-label" for="blockCheck">
            <a [routerLink]="['/terms']" target="_blank">Agree to terms and conditions</a>
          </label>
        </div>
      </div>
      <div class="submit-button">
        <button type="submit" class="btn btn-primary" [disabled]="!blockCheck">Submit my Report!</button>
      </div>
    </form>
    <!-- Para fazer download dos files-->
    <!--<a href="http://localhost:3000/api/report/download/isep-logo_2023_05_22_12_15_30.png">
      <button>
        Download Files
      </button>
    </a>-->
  </div>
</section>
<!-- End Report Create Section -->

<app-footer></app-footer>
