<app-header></app-header>

<!-- ======= Home Section ======= -->
<section id="hero" class="d-flex align-items-center">
  <div class="container" data-aos="zoom-out" data-aos-delay="100">
    <h1>Whistleblowing <span>Platform.</span></h1>
    <h2>Empower Change, Speak Up with CIIMAR Whistleblower<br>Your trusted channel for Ethical and Responsible Whistleblowing.</h2>
    <div class="d-flex">
      <a href="/report/create" class="btn-get-started scrollto">Create a Report!</a>
      <a href="/home#about" class="glightbox btn-watch-video"><i class="bi bi-info-circle"></i><span style="color:#FFFFFF">About us</span></a>
    </div>
  </div>
</section>
<!-- ======= Home Hero ======= -->

<!-- ======= About Section ======= -->
<section id="about" class="about section-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>About</h2>
      <h3>Find Out More About <span>Whistleblowing</span></h3>
      <p>Whistleblowing is the act of reporting or disclosing information about illegal, unethical, or improper activities within an organization.</p>
    </div>

    <div class="row">
      <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
        <br>
        <br>
        <img src="assets/img/vertical-ciimar.jpg" class="img-fluid" alt="">
      </div>
      <div class="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center" data-aos="fade-up" data-aos-delay="100">
        <h4 class="text-center">Rights and <span style="color: #64B1CF">Duties.</span></h4>
        <!--<p class="fst-italic">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          magna aliqua.
        </p>-->
        <ul>
          <li>
            <i class="bx bx-lock"></i>
            <div>
              <h5 style="color: #64B1CF">ANONYMITY</h5>
              <p>The whistleblower has the right to choose to remain anonymous when making a report.
                This allows you to feel more secure and protected when exposing irregularities.</p>
            </div>
          </li>
          <li>
            <i class="bx bxs-key"></i>
            <div>
              <h5 style="color: #64B1CF">CONFIDENTIALITY</h5>
              <p>The whistleblower has the right to have his or her information and identity kept confidential during the reporting process.
                It is CIIMAR's duty to ensure that sensitive information is not disclosed to unauthorized third parties.</p>
            </div>
          </li>
          <li>
            <i class="bx bxs-hand"></i>
            <div>
              <h5 style="color: #64B1CF">NON-RETALIATION</h5>
              <p>The whistleblower has the right to be protected against retaliation or reprisal for making a report.
                It is the organization's duty to ensure that the whistleblower does not suffer adverse consequences for acting in good faith.</p>
            </div>
          </li>
          <li>
            <i class="bx bx-briefcase"></i>
            <div>
              <h5 style="color: #64B1CF">FAIR AND IMPARTIAL TREATMENT</h5>
              <p>The whistleblower has the right to be treated fairly and impartially throughout the reporting process.
                This includes receiving a proper investigation, respecting your privacy and being informed of the progress of the report.
                It is the organization's duty to ensure fair and impartial treatment of all whistleblowers.</p>
            </div>
          </li>
        </ul>
      </div>
    </div>

  </div>
</section>
<!-- ======= End About Section ======= -->

<!-- ======= Frequently Asked Questions Section ======= -->
<section id="faq" class="faq section-bg">
  <div class="container" data-aos="fade-up">

    <div class="section-title">
      <h2>F.A.Q</h2>
      <h3>Frequently Asked <span>Questions</span></h3>
    </div>

    <div class="row justify-content-center">
      <div class="col-xl-10">
        <ul class="faq-list">

          <li>
            <div data-bs-toggle="collapse" href="#faq8" class="collapsed question">What are the deadlines for getting a response?<i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
            <div id="faq8" class="collapse" data-bs-parent=".faq-list">
              <p>
                Once the report has been submitted, the first response must be given within a maximum of 7 days to confirm receipt of the report.
                After that, the measures to be taken and their respective justifications must be announced within 3 months.
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" class="collapsed question" href="#faq1">What happens to my report after it is submitted?<i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
            <div id="faq1" class="collapse" data-bs-parent=".faq-list">
              <p>
                After submitting the report, it will go through an analysis and investigation process, which may be accepted or rejected and, depending on the state, actions will be taken against the suspect.
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq2" class="collapsed question">Who will have access to my report?<i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
            <div id="faq2" class="collapse" data-bs-parent=".faq-list">
              <p>
                The person in charge of receiving and handling the report is anonymous, so there is no influence on decision-making.
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq3" class="collapsed question">How can I track the status of my report?<i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
            <div id="faq3" class="collapse" data-bs-parent=".faq-list">
              <p>
                You must access the “Follow the Report” option in the tab "Report" and insert the code that was generated when creating the report.
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq4" class="collapsed question">I forgot/I lost the code generated to follow the report. What can I do?<i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
            <div id="faq4" class="collapse" data-bs-parent=".faq-list">
              <p>
                We advise you to create a new report referring to the previous one already created.
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq5" class="collapsed question">What is the difference between anonymity and confidentiality?<i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
            <div id="faq5" class="collapse" data-bs-parent=".faq-list">
              <p>
                The confidential option allows the whistleblower to be identified via email.
                The anonymous option does not require any type of identification.
                In the first case, CIIMAR ensures the complete confidentiality of the complaint.
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq6" class="collapsed question">If I choose the “anonymity” option, will no one know who I am?<i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
            <div id="faq6" class="collapse" data-bs-parent=".faq-list">
              <p>
                From the moment you choose the option to create the report as anonymous, your identity will not be known.
                However, it may be necessary, depending on the reported situation, to know the identity of the whistleblower if this is imperative for the investigation.
              </p>
            </div>
          </li>

          <li>
            <div data-bs-toggle="collapse" href="#faq7" class="collapsed question">I found an error/bug in the system. Who do I contact?<i class="bi bi-chevron-down icon-show"></i><i class="bi bi-chevron-up icon-close"></i></div>
            <div id="faq7" class="collapse" data-bs-parent=".faq-list">
              <p>
                To report problems on the platform, you must contact the IT team at CIIMAR through the Helpdesk system (https://helpdesk.ciimar.up.pt).
                To be able to maintain anonymity, you should create a ticket with the helpdesk email (helpdesk@ciimar.up.pt) and report what happens and when it happens.
              </p>
            </div>
          </li>

        </ul>
      </div>
    </div>

  </div>
</section>
<!-- End Frequently Asked Questions Section -->

<app-footer></app-footer>
