import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './Components/home/home.component';
import { RouterModule } from "@angular/router";
import { HeaderComponent } from './Components/header/header.component';
import { FooterComponent } from './Components/footer/footer.component';
import { ReportCreateComponent } from './Components/report-create/report-create.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import {MatSnackBar, MatSnackBarModule} from "@angular/material/snack-bar";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {IgxButtonModule, IgxRippleModule, IgxSnackbarComponent, IgxSnackbarModule} from "igniteui-angular";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatNativeDateModule} from "@angular/material/core";
import {NgbDatepickerModule, NgbInputDatepicker, NgbModule} from "@ng-bootstrap/ng-bootstrap";
import { ReportFollowComponent } from './Components/report-follow/report-follow.component';
import { ReportManageComponent } from './Components/report-manage/report-manage.component';
import {NgxPaginationModule} from "ngx-pagination";
import {Ng2OrderModule} from "ng2-order-pipe";
import {Ng2SearchPipeModule} from "ng2-search-filter";
import { SigninComponent } from './Components/signin/signin.component';
import {CommonModule, NgOptimizedImage} from "@angular/common";
import { RegisterComponent } from './Components/register/register.component';
import { Error401Component } from './Components/error401/error401.component';
import { Error404Component } from './Components/error404/error404.component';
import { Error403Component } from './Components/error403/error403.component';
import { TermsComponent } from './Components/terms/terms.component';

const material = [
  MatSnackBar,
  IgxSnackbarComponent
];

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ReportCreateComponent,
    ReportFollowComponent,
    ReportManageComponent,
    SigninComponent,
    RegisterComponent,
    Error401Component,
    Error404Component,
    Error403Component,
    TermsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    IgxButtonModule,
    IgxRippleModule,
    IgxSnackbarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,
    Ng2OrderModule,
    NgbModule,
    RouterModule.forRoot([
      {path: '', component: HomeComponent, pathMatch: 'full'},
      {path: 'home', component: HomeComponent, pathMatch: 'full'},
      {path: 'report/create', component: ReportCreateComponent, pathMatch: 'full'},
      {path: 'report/follow', component: ReportFollowComponent, pathMatch: 'full'},
      {path: 'report/manage', component: ReportManageComponent, pathMatch: 'full'},
      {path: 'terms', component: TermsComponent, pathMatch: 'full'},
      {path: 'signin', component: SigninComponent, pathMatch: 'full'},
      {path: 'register', component: RegisterComponent, pathMatch: 'full'},
      {path: 'error401', component: Error401Component, pathMatch: 'full'},
      {path: 'error403', component: Error403Component, pathMatch: 'full'},
      {path: 'error404', component: Error404Component, pathMatch: 'full'},
    ]),
    FormsModule,
    BrowserAnimationsModule,
    NgbInputDatepicker,
    NgOptimizedImage,
  ],
  providers: [material],
  bootstrap: [AppComponent],
})
export class AppModule {
}
