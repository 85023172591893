<header id="header" class="d-flex align-items-center">
  <div class="container d-flex align-items-center justify-content-between">
    <h1 class="logo"><a href="index.html"><img src="assets/img/ciimar-logo.png" class="img-fluid" alt=""></a></h1>
    <nav id="navbar" class="navbar">
      <ul>
        <li><a class="nav-link scrollto" href="#hero">Home</a></li>
        <li><a class="nav-link scrollto" href="/home#about">About</a></li>
        <li class="dropdown"><a class="dropdown-active active"><span>Report</span> <i class="bi bi-chevron-down"></i></a>
          <ul>
            <li><a [routerLink]="['/report/create']">Create a new Report</a></li>
            <li><a [routerLink]="['/report/follow']">Follow the Report</a></li>
            <!--<li><a [routerLink]="['/report/manage']">Manage the Report</a></li>-->
          </ul>
        </li>
        <li><a class="nav-link scrollto" href="/home#footer">Contact</a></li>
        <li></li>
        <li></li>
        <li></li>
        <li><a class="nav-link scrollto" href="/signin">Admin</a></li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>
  </div>
</header>

<!-- Report Follow Section -->
<section id="report" class="route section-bg">
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>Report</h2>
      <h3>Here you can follow the status of your <span>Report</span>.</h3>
      <p><i>“Transparency Matters, Reporting Matters with CIIMAR Whistleblower”</i></p>
    </div>
    <div class="form-group">
      <label for="follow_code">Code</label>
      <input mdbInput class="form-control" [(ngModel)]="follow_code" value="follow_code" name="follow_code" id="follow_code" class="form-control" placeholder="Insert the code">
    </div>
    <br>
    <div class="submit-button">
      <button type="submit" id="submit_code" class="btn btn-primary" [disabled]="follow_code === ''" (click)="onFollowClicked(follow_code)">Get the report!</button>
    </div>

    <form #followForm="ngForm" (ngSubmit)="followReport(followForm)">
      <div class="container" data-aos="fade-up">
        <div class="section-title">
          <h3>Check the <span>Report</span>.</h3>
        </div>
      </div>
      <!--Fill fields-->
      <div class="form-group">
        <label>Email</label><br>
        <input class="form-control" type="text" id="email" name="email" ngModel disabled>
      </div>
      <br>
      <div class="form-group">
        <label>Witnesses</label><br>
        <input class="form-control" type="text" name="witnesses" ngModel disabled>
      </div>
      <br>
      <div class="form-group">
        <label>Type</label><br>
        <input class="form-control" type="text" name="type" ngModel disabled>
      </div>
      <br>
      <div class="form-group">
        <label>Occurrence Date</label><br>
        <input class="form-control" type="text" name="occurrenceDate" ngModel disabled>
      </div>
      <br>
      <div class="form-group">
        <label>Report Date</label><br>
        <input class="form-control" type="text" name="reportDate" ngModel disabled>
      </div>
      <br>
      <div class="form-group">
        <label>Description</label><br>
        <textarea class="form-control" type="text" name="description" ngModel disabled></textarea>
      </div>
      <br>
      <div class="form-group">
        <label>Status</label><br>
        <input class="form-control" type="text" name="status" ngModel disabled>
      </div>
      <br>
      <div class="form-group">
        <label>Decision</label><br>
        <input class="form-control" type="text" name="decision" ngModel disabled>
      </div>
      <br>
      <div class="form-group">
        <label>Extra Info</label><br>
        <textarea class="form-control" type="text" name="extraInfo" ngModel disabled></textarea>
      </div>
      <br>
      <!--<div class="submit-button">
        <label>Evidences</label><br>
        <a href="{{downloadUrl}}Exemplo_2023_05_22_19_31_58.csv">
        <button class="btn btn-primary">Download Files</button>
      </a>
      </div>-->
    </form>
    <!-- Donwload files button  -->
    <div class="col-sm-5 col-md-6 submit-button" *ngIf="evidenceFilename!=''">
      <h5>Evidences submitted by you</h5>
      <br>
      <a href="{{downloadUrl}}{{evidenceFilename}}">
        <button class="btn btn-primary">Download Files</button>
      </a>
    </div>
  </div>
</section>
<!-- End Report Create Section -->

<app-footer></app-footer>
