<!-- ======= Footer ======= -->
<footer id="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">

        <div class="col-lg-3 col-md-6 footer-contact">
          <h1 class="logo"><a href="index.html"><img src="assets/img/ciimar-logo.png" class="img-fluid" alt="" width="180px"></a></h1>
          <p>
            <br>Av. General Norton de Matos, S/N<br>
            4450-208 Matosinhos, Portugal <br><br>
            <strong>Phone:</strong> +351 223 401 800<br>(national fixed call cost)<br>
            <strong>Email:</strong> helpdesk@ciimar.up.pt<br>
          </p>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/home#about">About us</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/report/create">New Report</a></li>
            <li><i class="bx bx-chevron-right"></i> <a href="/report/follow">Follow the Report</a></li>
            <!--<li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>-->
            <!--<li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>-->
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Do you want to report?<br>Do it here!</h4>
          <ul>
            <li><i class="bx bx-chevron-right"></i> <a [routerLink]="['/report/create']">Report!</a></li>
          </ul>
        </div>

        <div class="col-lg-3 col-md-6 footer-links">
          <h4>Our Social Networks</h4>
          <p>Have a look!</p>
          <div class="social-links mt-3">
            <a href="https://twitter.com/CiimarUp" class="twitter"><i class="bx bxl-twitter"></i></a>
            <a href="https://www.facebook.com/ciimar.up.pt/?locale=pt_PT" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="https://www.instagram.com/ciimar.up/" class="instagram"><i class="bx bxl-instagram"></i></a>
            <a href="https://www.linkedin.com/company/ciimar/mycompany/" class="linkedin"><i class="bx bxl-linkedin"></i></a>
          </div>
        </div>

      </div>
    </div>
  </div>

  <div class="container py-4">
    <div class="copyright">
      &copy; Copyright <strong><span>CIIMAR</span></strong> - All Rights Reserved
    </div>
    <div class="credits">

      Developed by <a href="#team">Tiago Pinto - IT CENTER</a>
    </div>
  </div>
</footer>
<!-- End Footer -->
