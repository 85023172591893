import {Component, OnInit} from '@angular/core';
import {RegisterService} from "../../Services/register.service";
import {NgForm} from "@angular/forms";
import {IRole} from "../../Models/irole.model";
import {RoleService} from "../../Services/role.service";
import {UserService} from "../../Services/user.service";
import {JwtHelperService} from "@auth0/angular-jwt";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit{
  public successMessage = 'New Manager was created successfully!';
  public errorMessage = 'Error while creating Manager';
  public registerCreated: boolean | undefined;
  public allUsers: any;
  type_role: string = "";
  role_d: string = "";
  rolesList: IRole[] = [];
  //Equivale ao role "Admin"
  permission: number[] = [1];

  constructor(private registerService: RegisterService, private roleService: RoleService, private userService: UserService) {
  }

  ngOnInit(): void {
    const token = JSON.parse(sessionStorage.getItem("token")!);

    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);

    if (token) {
      if(!this.permission.includes(decodedToken.role.props.value)){
        document.location.href = '/error403';
      } else {
        this.roleService.getAllRoles().subscribe((data: any) => {
          this.rolesList = data;
        });
      }
    }
  }

  chooseRole(e: any) {
    //ISTO ATRIBUI O VALOR 1 (ADMIN) ou 2 (MANAGER) ao type_role.
    this.type_role = e.target.value;
  }

  newRegister(form: NgForm) {
    this.registerService.signUp({
      firstName: form.value.firstName,
      lastName: form.value.lastName,
      email: form.value.email,
      password: form.value.password,
      role: form.value.role
    }).subscribe((data: any) => {
      this.registerCreated = true;
      this.scrollToTop();
      this.successMessage;
      this.waitThreeSeconds().then(() => {
        this.refresh();
      })
    }, (error: { message: string; }) => {
      this.registerCreated = false;
      this.errorMessage;
    })
  }

  dataRemove() {
    sessionStorage.removeItem("token");
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  waitThreeSeconds(): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }

  refresh(): void {
    window.location.reload();
  }
}
