import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ISignin} from "../Models/isignin.model";
import {IReport} from "../Models/ireport.model";

@Injectable({
  providedIn: 'root'
})
export class SigninService {
  signinURL = 'https://zerotolerance-be.ciimar.up.pt/api/auth/signin';

  constructor(private http: HttpClient) { }

  signIn(signin: ISignin): Observable<ISignin> {
    const headers = { 'Content-Type': 'application/json' };
    return this.http.post<ISignin>(this.signinURL, signin, {headers});
  }
}
