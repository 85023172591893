import {Component, OnInit} from '@angular/core';
import {SigninService} from "../../Services/signin.service";
import {NgForm} from "@angular/forms";

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit {
  public errorMessage = 'Error while signing in.';
  public signinDone: boolean | undefined;
  //editMode: boolean = false;

  constructor(private signInService: SigninService) {
  }

  ngOnInit(): void {
    const token = JSON.parse(sessionStorage.getItem("token")!);

    if (token) {
      document.location.href = '/report/manage';
    }
  }

  signinReport(form: NgForm) {
    this.signInService.signIn({
      email: form.value.email,
      password: form.value.password
    }).subscribe((data: any) => {
      this.signinDone = true;
      sessionStorage.setItem("token", JSON.stringify(data.token));
      document.location.href = '/report/manage';
      //this.successMessage;
    }, (error: { message: string; }) => {
      this.signinDone = false;
      this.errorMessage;
    })
  }

}
