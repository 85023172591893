import {Component, OnInit, ViewChild} from '@angular/core';
import {IReport} from "../../Models/ireport.model";
import {NgForm} from "@angular/forms";
import {ReportService} from "../../Services/report.service";
import * as moment from "moment/moment";

@Component({
  selector: 'app-report-manage',
  templateUrl: './report-manage.component.html',
  styleUrls: ['./report-manage.component.css']
})
export class ReportManageComponent implements OnInit {
  public successMessage = "The report was edited successfully!";
  public errorMessage = "Error while editing report.";
  public reportEdited: boolean | undefined;

  reports: IReport[] = [];
  @ViewChild('manageForm') form: NgForm | undefined;
  editMode: boolean = false;
  //Save the ReportId
  currentReportId: string = "";
  //Change page
  pag = 1;
  reportsPerPage: number = 5;
  //Sort By Report Date
  key: string = 'reportDate';
  reverse: boolean = false;
  //Select Button - Status
  status_r: string = "";
  status_list= ["Submitted", "Under Review", "Investigation", "Resolved", "Closed"];
  //Select Button - Decision
  decision_r: string = "";
  decision_list= ["Pending", "Rejected", "Approved"];
  //Evidences Link
  downloadUrl: string = "https://zerotolerance-be.ciimar.up.pt/api/report/download/";
  evidenceFilename: string | undefined = "";
  evidenceFilenameManager: string | undefined = "";
  //Evidence/Provas Manager
  evidences_manager: string = "";
  //search
  search_aux: any;
  temp: any;

  constructor(private reportService: ReportService) {
  }

  ngOnInit(): void {
    const token = JSON.parse(sessionStorage.getItem("token")!);

    if (!token) {
      document.location.href = '/error401';
    } else {
      this.reportService.getAllReports().subscribe((data: any) => {
        this.reports = data;
      })
    }
  }

  manageReport(form: NgForm) {
    console.log("editMode após: " + this.editMode);
    let testData:FormData = new FormData();
    let file: any;
    let parts: any;
    let finalName: any;
    console.log("COMO ESTÁ? " + this.evidenceFilenameManager)

    if (this.evidenceFilenameManager != "") {
      finalName = this.evidenceFilenameManager;
      // @ts-ignore
    } else if (document.getElementById("evidence_manager").files[0] === null || document.getElementById("evidence_manager").files[0] === undefined) {
      finalName = "";
    } else {
      // @ts-ignore
      file = document.getElementById("evidence_manager").files[0] as File;
      parts = file.name.split('.') as string[];
      finalName = `${parts[0]}_${moment().format('YYYY_MM_DD_HH_mm_ss')}.${parts[1]}`
      testData.append('file_upload', file, finalName);
    }

    this.reportService.uploadImage(testData).subscribe((data: any) => {
      this.reportService.editReportById({
        reportId: this.currentReportId,
        description: form.value.description,
        email: form.value.email,
        witnesses: form.value.witnesses,
        status: form.value.status,
        code: form.value.code,
        type: form.value.type,
        anonymous: form.value.anonymous,
        occurrenceDate: form.value.occurrenceDate,
        reportDate: form.value.reportDate,
        decision: form.value.decision,
        extraInfo: form.value.extraInfo,
        evidences: form.value.evidences,
        belongsTo: form.value.belongsTo,
        suspect: form.value.suspect,
        evidencesManager: finalName
      }).subscribe((data: any) => {
        this.reportEdited = true;
        this.scrollToTop();
        this.successMessage;
        this.waitThreeSeconds().then(() => {
          this.refresh();
        })
      }, (error: { message: string; }) => {
        this.reportEdited = false;
        this.errorMessage;
      })
    })
  }

  onEditClicked(reportId: string) {
    this.currentReportId = reportId;

    //Get the report Code
    let actual_report = this.reports?.find((report) => {
      return report.reportId === reportId;
    })

    if (actual_report?.email === undefined) {
      // @ts-ignore
      actual_report.email = "";
    }

    if (actual_report?.witnesses === undefined) {
      // @ts-ignore
      actual_report.witnesses = "No witnesses have been identified.";
    }

    this.evidenceFilename = actual_report?.evidences.toString();
    this.evidenceFilenameManager = actual_report?.evidencesManager.toString();

    //populate the form with the report details
    this.form?.setValue({
      // @ts-ignore
      anonymous: actual_report.anonymous,
      // @ts-ignore
      email: actual_report.email,
      // @ts-ignore
      witnesses: actual_report.witnesses,
      // @ts-ignore
      type: actual_report.type,
      // @ts-ignore
      code: actual_report.code,
      // @ts-ignore
      occurrenceDate: actual_report.occurrenceDate,
      // @ts-ignore
      reportDate: actual_report.reportDate,
      // @ts-ignore
      description: actual_report.description,
      // @ts-ignore
      status: actual_report.status,
      // @ts-ignore
      decision: actual_report.decision,
      // @ts-ignore
      extraInfo: actual_report.extraInfo,
      // @ts-ignore
      belongsTo: actual_report?.belongsTo,
      // @ts-ignore
      suspect: actual_report?.suspect,
      evidence_manager: "",
    });

    //Button value to be able to click
    this.editMode = true;
  }

  //Sort By ReportDate
  sort(key: string) {
    this.key = key;
    this.reverse = !this.reverse;
  }

  chooseStatus(e: any) {
    this.status_r = e.target.value;
  }

  chooseDecision(e: any) {
    this.decision_r = e.target.value;
  }

  dataRemove() {
    sessionStorage.removeItem("token");
  }

  uploadFile(e: any): void {
    this.evidences_manager = e.target.files;
  }

  refresh(): void {
    window.location.reload();
  }

  scrollToTop(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  waitThreeSeconds(): Promise<void> {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 2000);
    });
  }

  search() {
    if (this.search_aux == "") {
      this.ngOnInit();
    } else {
      this.reports = this.reports?.filter(res => {
        let wh = this.searchReportByDescription(res.reportId);
        console.log("wh: " + wh);
        return wh.toLocaleLowerCase().match(this.search_aux.toLocaleLowerCase());
      });
    }
  }

  searchReportByDescription(reportId: string) {
    for (let i = 0; i < this.reports.length; i++) {
      if (reportId === this.reports[i].reportId) {
        this.temp = this.reports[i].description;
      }
    }
    return this.temp;
  }

}
