<section class="h-100 gradient-form" style="background-color: #eee;">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-xl-10">
        <div class="card rounded-3 text-black">
          <div class="row g-0">
            <div class="col-lg-6">
              <div class="card-body p-md-5 mx-md-4">
                <div *ngIf="!signinDone && signinDone != null" class="alert alert-warning d-flex align-items-center">
                  <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  {{this.errorMessage}}
                </div>
                <br>
                <br>
                <div class="text-center">
                  <a href="index.html"><img src="assets/img/ciimar-logo.png" class="img-fluid" alt="" style="width: 185px;"></a>
                </div>
                <form #signinForm="ngForm" (ngSubmit)="signinReport(signinForm)">
                  <br>
                  <br>
                  <br>
                  <div class="form-outline mb-4">
                    <label class="form-label" for="email">Email Address</label>
                    <input type="email" id="email" name="email" class="form-control" ngModel>
                  </div>
                  <div class="form-group">
                    <label class="form-label" for="password">Password</label><br>
                    <input class="form-control" type="password" name="password" id="password" ngModel>
                  </div>
                  <div class="submit-button text-center">
                    <br>
                    <input type="submit" class="btn btn-primary">
                  </div>
                </form>
              </div>
            </div>
            <div class="col-lg-6 d-flex align-items-center gradient-custom-2">
              <a><img src="assets/img/signin-vertical.jpeg" class="img-fluid" alt=""></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
