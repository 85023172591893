<header id="header" class="d-flex align-items-center">
  <div class="container d-flex align-items-center justify-content-between">
    <h1 class="logo"><a href="index.html"><img src="assets/img/ciimar-logo.png" class="img-fluid" alt=""></a></h1>
    <nav id="navbar" class="navbar">
      <ul>
        <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
        <li><a class="nav-link scrollto" href="/home#about">About</a></li>
        <li class="dropdown"><a id="reportOpt"><span>Report</span> <i class="bi bi-chevron-down"></i></a>
          <ul>
            <li><a [routerLink]="['/report/create']" id="createReportOpt">Create a new Report</a></li>
            <li><a [routerLink]="['/report/follow']" id="followReportOpt">Follow the Report</a></li>
            <!--<li><a [routerLink]="['/report/manage']">Manage the Report</a></li>-->
          </ul>
        </li>
        <li><a class="nav-link scrollto" href="/home#footer">Contact</a></li>
        <li></li>
        <li></li>
        <li></li>
        <li><a class="nav-link scrollto bx-align-left" href="/signin" id="adminLogin">Admin</a></li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>
  </div>
</header>
