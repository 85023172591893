<app-header></app-header>

<section id="about" class="about section-bg" style="padding-top: 50px!important">
  <div class="container" data-aos="fade-up">

    <div class="section-title justify-content-left">
      <h2>Terms & Conditions</h2>
    </div>
    <div class="justify-content-left">
      <h3>CIIMAR - Interdisciplinary Centre of Marine and Environmental Research</h3>
      <br>
      <h5><strong>RGPD</strong></h5>
      <p>This platform was created under Law n.º 93/2021, of December 20, which establishes the general regime for the protection
        of whistleblowers, transposing Directive (EU) 2019/1937 of the European Parliament and of the Council, of October 23 , 2019,
        related to violations of European Union law, and a set of obligations for companies and other public entities regarding the
        protection of whistleblowers and the implementation of internal channels and procedures.</p>
      <p> This mechanism allows a safe presentation, monitoring of complaints, guaranteeing: their preservation, the confidentiality
        of the identity or anonymity of the whistleblowers and third parties and the limitation of access to authorized persons in
        accordance with the privacy and security policy, the General Data Protection Regulation - RGPD and related legislation.</p>
    </div>
  </div>
</section>

<app-footer></app-footer>
