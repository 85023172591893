<header id="header" class="d-flex align-items-center">
  <div class="container d-flex align-items-center justify-content-between">
    <h1 class="logo"><a href="index.html"><img src="assets/img/ciimar-logo.png" class="img-fluid" alt=""></a></h1>
    <nav id="navbar" class="navbar">
      <ul>
        <li><a class="nav-link scrollto" href="#hero">Home</a></li>
        <li><a class="nav-link scrollto" href="/home#about">About</a></li>
        <li class="dropdown"><a><span>Report</span> <i class="bi bi-chevron-down"></i></a>
          <ul>
            <li><a [routerLink]="['/report/create']">Create a new Report</a></li>
            <li><a [routerLink]="['/report/follow']">Follow the Report</a></li>
            <!--<li><a [routerLink]="['/report/manage']">Manage the Report</a></li>-->
          </ul>
        </li>
        <li><a class="nav-link scrollto" href="/home#footer">Contact</a></li>
        <li></li>
        <li></li>
        <li></li>
        <li><a class="nav-link scrollto active" href="/signup">Register User</a></li>
        <li><a class="nav-link scrollto" href="/" (click)="dataRemove()">LogOut</a></li>
      </ul>
      <i class="bi bi-list mobile-nav-toggle"></i>
    </nav>
  </div>
</header>

<!-- Register Section -->
<section id="report" class="route section-bg">
  <div class="container" data-aos="fade-up">
    <div class="section-title">
      <h2>Register</h2>
      <h3>Here you can register a new <span>Manager</span>.</h3>
      <p><i>“Together for Transparency, Reporting Wrongdoing with CIIMAR Whistleblower”</i></p>
    </div>
    <div class="row mt-4">
      <div class="col">
        <div class="row">
          <div *ngIf="registerCreated" class="alert alert-success d-flex align-items-center" role="alert">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:">
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
            {{this.successMessage}}
          </div>
          <div *ngIf="!registerCreated && registerCreated != null" class="alert alert-warning d-flex align-items-center">
            <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Warning:">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
            {{this.errorMessage}}
          </div>
        </div>
      </div>
    </div>
    <form #registerForm="ngForm" (ngSubmit)="newRegister(registerForm)">
      <br>
      <div class="form-group">
        <label class="form-label" for="firstName">First Name</label>
        <input ngModel class="form-control" type="text" id="firstName" name="firstName" placeholder="Insert first name">
      </div>
      <br>
      <div class="form-group">
        <label class="form-label" for="lastName">Last Name</label>
        <input ngModel class="form-control" type="text" id="lastName" name="lastName" placeholder="Insert last name">
      </div>
      <br>
      <div class="form-group">
        <label class="form-label" for="email">Email</label>
        <input ngModel class="form-control" type="email" id="email" name="email" placeholder="Insert email">
      </div>
      <br>
      <div class="form-group">
        <label class="form-label" for="password">Password</label>
        <input ngModel class="form-control" type="password" id="password" name="password" placeholder="Insert password">
      </div>
      <br>
      <div class="form-group">
        <label for="role">Role</label>
        <select name="role" id="role" class="form-control" [(ngModel)]="role_d" (change)="chooseRole($event)">
          <option>Select role</option>
          <option *ngFor="let role of rolesList" [value]="role.roleId">
            {{role.name}}
          </option>
        </select>
      </div>
      <br>
      <br>
      <div class="submit-button">
        <button type="submit" class="btn btn-primary">Submit new user!</button>
      </div>
    </form>
  </div>
</section>
<!-- End Report Create Section -->

<app-footer></app-footer>
